*, :before, :after {
  box-sizing: border-box;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  line-height: 1;
}

:root {
  font-variant-ligatures: common-ligatures;
  text-rendering: optimizelegibility;
  font-smoothing: antialiased;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

body {
  margin: 0;
}

img {
  height: auto;
  display: block;
}

img, canvas, iframe, video, svg, select, textarea {
  max-width: 100%;
}

[hidden] {
  display: none;
}

body {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

/*# sourceMappingURL=index.dd579c2e.css.map */
