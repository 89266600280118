*,
*::before,
*::after {
  box-sizing: border-box;
  line-height: 1;
  backface-visibility: hidden;
}

:root {
  font-variant-ligatures: common-ligatures;
  text-rendering: optimizeLegibility;
  font-smoothing: antialiased;  
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol";
}

body {
  margin: 0;
}

img {
  display: block;
  height: auto;
}

img,
canvas,
iframe,
video,
svg,
select,
textarea {
  max-width: 100%;
}

[hidden] {
  display: none;
}

body {
   -webkit-tap-highlight-color: transparent;
   -webkit-touch-callout: none;
}

